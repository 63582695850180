/* Modal Layout */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
}

.modalContainer {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow: hidden;
    position: relative;
    margin: auto;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 1px solid #eee;
}

.modalTitle {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: #333;
}

.closeButton {
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    transition: color 0.2s;
}

.closeButton:hover {
    color: #000;
}

.modalBody {
    padding: 0;
    max-height: calc(90vh - 80px);
    overflow-y: auto;
}


/* Product Grid Layout */

.productGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 30px;
}


/* Product Image Section */

.productImageContainer {
    position: relative;
}

.imageNavigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.mainImageWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.productImage {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.imageNavButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    z-index: 2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.prevButton {
    left: 10px;
}

.nextButton {
    right: 10px;
}

.disabledButton {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
}

.imageNavButton:hover:not(.disabledButton) {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.thumbnailContainer {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    flex-wrap: wrap;
}

.thumbnail {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.2s;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbnail:hover {
    border-color: #ddd;
}

.activeThumbnail {
    border-color: #000;
}


/* Product Details Section */

.productDetails {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.productInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.priceContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.price {
    font-size: 28px;
    font-weight: 600;
    color: #000;
}

.originalPrice {
    font-size: 20px;
    color: #999;
    text-decoration: line-through;
}

.productActions {
    display: flex;
    gap: 15px;
}

.actionButton {
    background: none;
    border: 1px solid #ddd;
    padding: 8px 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
}

.actionButton:hover {
    background-color: #f5f5f5;
    border-color: #ccc;
}


/* Size Selector */

.sizeSelector {
    margin-top: 10px;
}

.sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.sectionTitle h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.sizeError {
    color: #d63031;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
}

.showError {
    opacity: 1;
}

.sizeOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.sizeOption {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    background: none;
    cursor: pointer;
    transition: all 0.2s;
}

.sizeOption:hover:not(.unavailableSize) {
    border-color: #000;
}

.selectedSize {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.unavailableSize {
    color: #ccc;
    border-color: #eee;
    background-color: #f9f9f9;
    cursor: not-allowed;
    text-decoration: line-through;
}


/* Quantity Selector */

.quantitySelector {
    margin-top: 10px;
}

.quantityControls {
    display: flex;
    align-items: center;
}

.quantityButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #ddd;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s;
}

.quantityButton:first-child {
    border-radius: 8px 0 0 8px;
}

.quantityButton:last-child {
    border-radius: 0 8px 8px 0;
}

.quantityButton:hover:not(:disabled) {
    background-color: #f5f5f5;
}

.quantityButton:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.quantityValue {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}


/* Cart Actions */

.cartActions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.addToCartButton,
.goToCartButton,
.removeButton {
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
}

.addToCartButton {
    background-color: #000;
    color: #fff;
}

.addToCartButton:hover {
    background-color: #333;
}

.goToCartButton {
    background-color: #f5f5f5;
    color: #000;
    border: 1px solid #ddd;
}

.goToCartButton:hover {
    background-color: #eee;
}

.removeButton {
    background-color: #f44336;
    color: #fff;
}

.removeButton:hover {
    background-color: #d32f2f;
}


/* Product Tabs */

.productTabs {
    margin-top: 30px;
}

.tabsHeader {
    display: flex;
    border-bottom: 1px solid #ddd;
}

.tabButton {
    padding: 12px 20px;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}

.tabButton:hover {
    color: #000;
}

.activeTab {
    color: #000;
    font-weight: 600;
}

.activeTab::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
}

.tabContent {
    padding: 20px 0;
}

.descriptionTab,
.detailsTab,
.shippingTab {
    line-height: 1.6;
    color: #333;
}

.productDetailsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.productDetailsList li {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}

.productDetailsList li:last-child {
    border-bottom: none;
}


/* Related Products Section */

.relatedProductsSection {
    padding: 30px;
    border-top: 1px solid #eee;
}

.relatedTitle {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.relatedProductsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.relatedProductCard {
    cursor: pointer;
    transition: transform 0.2s;
}

.relatedProductCard:hover {
    transform: translateY(-5px);
}

.relatedImageContainer {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
}

.relatedProductImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s;
}

.relatedProductCard:hover .relatedProductImage {
    transform: scale(1.05);
}

.relatedProductInfo {
    padding: 10px 0;
}

.relatedProductName {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 5px 0;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.relatedProductPrice {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.noRelatedProducts {
    text-align: center;
    color: #666;
    padding: 20px;
}


/* Loading Spinner */

.loadingSpinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


/* Success Message */

.successMessage {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 2000;
    animation: slideIn 0.3s forwards;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.successContent {
    display: flex;
    align-items: center;
    background-color: #4caf50;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.successIcon {
    font-size: 20px;
    margin-right: 10px;
}

.successText {
    font-size: 16px;
    font-weight: 500;
}


/* Responsive Styles */

@media (max-width: 992px) {
    .productGrid {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .relatedProductsGrid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .modalContainer {
        width: 95%;
        max-height: 95vh;
        margin: 0 auto;
    }
    .modalHeader {
        padding: 15px 20px;
    }
    .modalTitle {
        font-size: 20px;
    }
    .productGrid {
        padding: 20px;
    }
    .relatedProductsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
    .relatedProductsSection {
        padding: 20px;
    }
}

@media (max-width: 576px) {
    .modalOverlay {
        padding: 20px;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        background-color: rgba(0, 0, 0, 0.75);
    }
    .modalContainer {
        width: 95%;
        max-width: 95%;
        margin: auto;
        border-radius: 12px;
        max-height: 90vh;
        height: auto;
        min-height: 50vh;
        max-height: 95vh;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        overflow: hidden;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    }
    .modalHeader {
        padding: 15px;
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    .closeButton {
        font-size: 22px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        border-radius: 50%;
    }
    .modalBody {
        max-height: calc(85vh - 60px);
        overflow-y: auto;
        padding-bottom: 80px;
        /* Add space at the bottom for better scrolling */
    }
    .productGrid {
        padding: 15px;
        gap: 15px;
    }
    .price {
        font-size: 24px;
    }
    .originalPrice {
        font-size: 18px;
    }
    .sizeOptions {
        gap: 8px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .sizeOption {
        width: 40px;
        height: 40px;
    }
    .tabButton {
        padding: 10px 15px;
        font-size: 14px;
    }
    .relatedProductsGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    .relatedProductImage {
        height: 150px;
    }
    .cartActions {
        flex-direction: column;
        gap: 10px;
    }
    .addToCartButton,
    .goToCartButton,
    .removeButton {
        width: 100%;
    }
}