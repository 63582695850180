.checkout-container {
    max-width: 800px;
    margin: 0 auto;
    /* padding: 20px; */
}

.buttons {
    border: none;
    background: white;
}

.success-message {
    text-align: center;
    margin-top: 50px;
}

.success-message iframe {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

.continue-shopping-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.checkout-form {
    margin-bottom: 20px;
}

.checkout-summary {
    margin-bottom: 20px;
}

.summary-toggle {
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.summary-toggle p {
    margin: 0;
}

.summary-content {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.total-section {
    margin-top: 20px;
}

.cart-total,
.cart-delivery-fee,
.cart-grand-total {
    margin-bottom: 5px;
}

.buttomCartCheckout {
    /* border-bottom: 1px solid #000000; */
    padding-bottom: 10px;
    /* padding: 10px; */
}

.checkoutImage {
    width: 65px;
    height: 65px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;
}

.checkoutimageNumber {
    background-color: #968e8e;
    color: #fff;
    font-size: 11px;
    padding: 0;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
    position: relative;
    bottom: 6.5em;
    margin: 0;
    left: 4.3em;
}

.summaryOerder {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
}

.summaryOerderF {
    font-size: 14px;
}

.sumcon {
    display: flex;
}

.paymentDelivery {
    padding: 20px;
}


/* Input and Select styles */

.ant-input,
.ant-select-selector {
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 8px;
    font-size: 16px;
}

.ant-input:focus,
.ant-select-selector:focus {
    border-color: #1890ff;
}


/* Form Item spacing */

.ant-form-item {
    margin-bottom: 16px;
}


/* Checkbox style */

.ant-checkbox-wrapper {
    font-size: 16px;
}

.ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
}


/* Country, State, and City Select styles */

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    width: 100%;
    max-width: 300px;
}

.ant-select-dropdown {
    max-height: 50%;
    /* Adjust the max-height as needed */
    overflow-y: auto;
    position: absolute;
    z-index: 1050;
    top: 100%;
    left: 0;
    /* margin-top: 4px; */
}

.ant-form-item-control-input-content {
    display: flex;
    align-items: center;
}


/* Consistent Select styles */

.ant-select-single.ant-select-show-arrow .ant-select-selector {
    height: 40px;
    /* Adjust the height as needed */
    display: flex;
    align-items: center;
    width: 90%;
    border: none;
    outline: none;
    border-bottom: 1px solid #ccc;
}

.ant-select-single.ant-select-show-arrow .ant-select-selector :focus {
    border: none !important;
    outline: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 40px;
    /* Match the height of the selector */
}

.selectDropdown .ant-select-selector {
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border-color 0.3s;
}

.selectDropdown .ant-select-selector:hover {
    /* border-color: #40a9ff; */
}

.selectDropdown .ant-select-selector:focus,
.selectDropdown .ant-select-focused .ant-select-selector {
    /* border-color: #40a9ff; */
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.selectDropdown .ant-select-selection-item,
.selectDropdown .ant-select-selection-placeholder {
    line-height: 40px;
    font-size: 16px;
}

.selectDropdown .ant-select-arrow {
    color: #999;
}

.bottomButton {
    /* margin-bottom: 5em; */
    /* border-bottom: 2px solid #ccc; */
    padding-bottom: 1em;
    padding-top: 0.5em;
}

.bottomButton button {
    background-color: #500157;
    border: none;
    color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}

.show {
    /* display: none; */
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}


/* 
.form-control:focus {
  border-color: #ff7418;
  outline: none;
}

.form-control:focus,
select.form-control:focus {
  border-color: #42ff18;
  outline: none;
} */

textarea:focus,
input:focus {
    outline: none;
}

.form-control {
    height: 45px;
}

input:focus {
    outline: none;
}

.form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.payemntImg {
    width: 120px;
    height: 20px;
}

.payra {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PayStackMo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.PayStackMo,
.bankMo {
    background-color: #dfdfdfa5;
}

.PayStackMo img {
    width: 90px;
    height: 90px;
}

.bankMo,
.PayStackMo p {
    font-size: 13px;
    margin: 0;
    padding: 10px;
    text-align: center;
}

.payra.active {
    background-color: #f0f0f0;
    border: 1px solid #000000;
    border-radius: 2px;
    padding: 15px;
}

.payra.incative {
    border: 1px solid #c4bebe;
    padding: 15px;
    border-radius: 2px;
}

.btnPaynows {
    width: 100%;
}

.btnModal {
    width: 40%;
    outline: none;
    background-color: #500157;
    color: #fff;
    border: none;
    padding: 15px;
    margin: 1em;
    border-radius: 25px;
}

.btnModaldiv {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 2em;
}

.within {
    border: 1px solid #000000;
    padding: 10px;
    font-size: 13px;
    border-radius: 4px;
}

.tran {
    margin: 10px;
    margin-left: 0;
    font-size: 13px;
}

.Confirmmodal {
    /* z-index: 1000; */
    background-color: #4d4d4dd8;
    position: absolute;
    bottom: 30%;
    width: 80%;
    border-radius: 25px;
    padding: 1em;
    color: #fff;
}

.paymentOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

@media (max-width: 767px) {
    .btnModal {
        width: 100%;
        outline: none;
        background-color: #500157;
        color: #fff;
        border: none;
        padding: 15px;
        margin: 1em;
        border-radius: 25px;
    }
    .btnModaldiv {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        gap: 0.1em;
        flex-direction: column;
    }
}


/* Payment Options Styling */

.payment-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.payment-option {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.payment-option:hover {
    border-color: #6a0dad;
    background-color: #f9f5ff;
}

.payment-option.active {
    border-color: #6a0dad;
    background-color: #f9f5ff;
}

.payment-option input[type="radio"] {
    margin-right: 10px;
}

.payment-option label {
    flex: 1;
    font-weight: 500;
}

.payment-logos {
    display: flex;
    align-items: center;
    gap: 5px;
}

.payment-logos img {
    height: 24px;
    object-fit: contain;
}


/* Enhanced Payment Modal Styling */

.payment-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal-content {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    padding: 30px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal-content h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.modal-content p {
    margin-bottom: 15px;
    line-height: 1.5;
}

.close-modal-btn {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    transition: all 0.2s;
    display: block;
    width: 100%;
}

.close-modal-btn:hover {
    background-color: #e5e5e5;
}


/* PayPal specific styling */

.paypal-info {
    background-color: #f8f9fa;
    border-left: 4px solid #0070ba;
    padding: 15px;
    margin: 20px 0;
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    border-radius: 0 8px 8px 0;
}

.paypal-note {
    background-color: #fff8e1;
    border-left: 4px solid #ffc107;
    padding: 15px;
    margin: 20px 0;
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    border-radius: 0 8px 8px 0;
}

.paypal-sandbox-info {
    background-color: #e3f2fd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 14px;
}

.paypal-sandbox-info strong {
    display: block;
    margin-bottom: 10px;
    color: #0070ba;
    font-size: 16px;
}

.paypal-sandbox-info ul {
    margin: 10px 0;
}

.paypal-sandbox-info li {
    margin-bottom: 8px;
}

.paypal-troubleshooting {
    margin: 20px 0;
    font-size: 14px;
}

.paypal-troubleshooting summary {
    cursor: pointer;
    color: #0070ba;
    font-weight: 500;
    padding: 8px 0;
}

.paypal-troubleshooting ul {
    margin-top: 10px;
    padding-left: 20px;
}

.paypal-troubleshooting li {
    margin-bottom: 8px;
    line-height: 1.5;
}

.paypal-button-container {
    margin: 25px 0;
}


/* Bank transfer modal styling */

.bankMo {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    padding: 30px;
    width: 90%;
    max-width: 500px;
    margin: 20px auto;
    animation: fadeIn 0.3s ease-in-out;
}

.bankMo p {
    font-size: 15px;
    line-height: 1.6;
    color: #333;
    white-space: pre-line;
}


/* Payment options styling */

.payment-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
}

.payment-option {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: all 0.2s;
    cursor: pointer;
}

.payment-option:hover {
    border-color: #0070ba;
    background-color: #f8f9fa;
    transform: translateY(-2px);
}

.payment-option.active {
    border-color: #0070ba;
    background-color: #e3f2fd;
}

.payment-option input[type="radio"] {
    margin-right: 15px;
}

.payment-option label {
    font-weight: 500;
    flex-grow: 1;
}

.payment-logos {
    display: flex;
    align-items: center;
    gap: 10px;
}

.payment-logos img {
    height: 24px;
    object-fit: contain;
}


/* Responsive adjustments */

@media (max-width: 768px) {
    .modal-content {
        padding: 20px;
        width: 95%;
    }
    .payment-option {
        padding: 12px;
    }
}

.payment-modal .paypal-button-container {
    width: 100%;
    margin: 15px 0;
}

.payment-modal .paypal-wrapper {
    width: 100%;
}

.payment-modal .paypal-button {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
}

.paypal-loading {
    text-align: center;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin: 10px 0;
    font-weight: bold;
    color: #3b7bbf;
    border: 1px solid #ddd;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        background-color: #f5f5f5;
    }
    50% {
        background-color: #e0e7f1;
    }
    100% {
        background-color: #f5f5f5;
    }
}

.paypal-error {
    background-color: #fff0f0;
    border: 1px solid #ffcaca;
    color: #d63031;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 14px;
    text-align: center;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #0070ba;
    /* PayPal blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.stripe-wrapper {
    width: 100%;
    margin: 15px 0;
}

.stripe-button {
    background-color: #6772e5;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.stripe-button:hover {
    background-color: #5469d4;
}

.stripe-button:disabled {
    background-color: #b2b7e5;
    cursor: not-allowed;
}

.stripe-error {
    background-color: #fff0f0;
    border: 1px solid #ffcaca;
    color: #d63031;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 14px;
    text-align: center;
}

.stripe-info {
    background-color: #f8f9fa;
    border-left: 4px solid #6772e5;
    padding: 10px 15px;
    margin: 15px 0;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    border-radius: 0 4px 4px 0;
}

.stripe-button-container {
    width: 100%;
    margin: 15px 0;
}

.paypal-processing {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    color: #495057;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
}


/* Bank Transfer Modal Styling */

.bank-info {
    margin: 20px 0;
}

.bank-info-header {
    margin-bottom: 20px;
}

.bank-info-header h4 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #333;
}

.bank-info-header p {
    color: #666;
    font-size: 14px;
}

.bank-details {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.bank-detail-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #eee;
}

.bank-detail-item:last-child {
    border-bottom: none;
}

.detail-label {
    font-weight: 500;
    color: #666;
}

.detail-value {
    font-weight: 600;
    color: #333;
}

.bank-note {
    background-color: #fff8e1;
    border-left: 4px solid #ffc107;
    padding: 15px;
    margin: 20px 0;
    border-radius: 0 8px 8px 0;
}

.bank-note p {
    margin-bottom: 8px;
}

.bank-note a {
    color: #0070ba;
    text-decoration: none;
    font-weight: 600;
}

.bank-note a:hover {
    text-decoration: underline;
}

.exchange-rate-info {
    background-color: #f0f4f8;
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
    font-size: 14px;
}

.exchange-rate-info p {
    margin-bottom: 8px;
    color: #555;
}

.bank-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
}

.confirm-transfer-btn {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.confirm-transfer-btn:hover {
    background-color: #218838;
}


/* PayPal Modal Styling */

.modal-subtitle {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 25px;
}

.payment-method-info {
    margin: 20px 0;
}

.payment-method-header {
    margin-bottom: 20px;
}

.payment-method-header h4 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #333;
}

.payment-method-header p {
    color: #666;
    font-size: 14px;
}

.payment-method-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.payment-method-option {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    transition: all 0.2s;
    border: 1px solid #eee;
}

.payment-method-option:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    border-color: #0070ba;
}

.payment-method-option img {
    width: auto;
    height: 30px;
    margin-right: 15px;
}

.option-details {
    flex: 1;
}

.option-details h5 {
    font-size: 16px;
    margin: 0 0 5px 0;
    color: #333;
}

.option-details p {
    font-size: 14px;
    color: #666;
    margin: 0;
}


/* PayPal Button Styling */

.paypal-wrapper {
    width: 100%;
    margin: 15px 0;
}

.paypal-button {
    background-color: #0070ba;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.paypal-button:hover {
    background-color: #005ea6;
}

.paypal-button:disabled {
    background-color: #8eb9d8;
    cursor: not-allowed;
}

.paypal-button img {
    height: 20px;
    margin-right: 10px;
}


/* Responsive adjustments for payment methods */

@media (max-width: 768px) {
    .payment-method-option {
        padding: 12px;
    }
    .payment-method-option img {
        height: 25px;
        margin-right: 10px;
    }
    .option-details h5 {
        font-size: 14px;
    }
    .option-details p {
        font-size: 12px;
    }
}