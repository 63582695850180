/* Modern Footer Styles */

.footer {
    background: linear-gradient(135deg, #500157 0%, #7b0282 100%);
    color: #fff;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    margin-top: 4rem;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.footer-top {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 3rem;
    padding: 4rem 0 3rem;
}


/* Brand Section */

.footer-brand {
    display: flex;
    flex-direction: column;
}

.brand-name {
    font-size: 1.75rem;
    font-weight: 700;
    margin: 0 0 0.5rem;
    letter-spacing: 1px;
}

.brand-tagline {
    font-size: 0.9rem;
    margin: 0 0 1.5rem;
    opacity: 0.8;
    font-weight: 300;
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-top: auto;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.social-links a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-3px);
}


/* Links Section */

.footer-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.link-group h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1.25rem;
    position: relative;
    padding-bottom: 0.75rem;
}

.link-group h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.3);
}

.link-group ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.link-group li {
    margin-bottom: 0.75rem;
}

.link-group a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    display: inline-block;
}

.link-group a:hover {
    color: #fff;
    transform: translateX(3px);
}


/* Newsletter Section */

.newsletter {
    display: flex;
    flex-direction: column;
}

.newsletter h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 1rem;
    position: relative;
    padding-bottom: 0.75rem;
}

.newsletter h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.3);
}

.newsletter p {
    font-size: 0.9rem;
    margin: 0 0 1.25rem;
    opacity: 0.8;
}

.newsletter-form {
    margin-bottom: 1.5rem;
}

.input-group {
    position: relative;
    display: flex;
    align-items: center;
}

.input-icon {
    position: absolute;
    left: 12px;
    color: #500157;
    font-size: 1rem;
}

.newsletter input {
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
}

.newsletter input:focus {
    outline: none;
    background-color: #fff;
}

.subscribe-btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #500157;
    border: none;
    border-radius: 0 4px 4px 0;
    color: white;
    width: 40px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscribe-btn:hover {
    background-color: #3a0140;
}

.success-message {
    color: #4caf50;
    font-size: 0.85rem;
    margin-top: 0.5rem;
}

.currency-selector {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: auto;
}

.currency-selector p {
    margin: 0;
    font-size: 0.85rem;
}


/* Footer Bottom */

.footer-bottom {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 1.5rem 0;
    text-align: center;
}

.copyright {
    font-size: 0.85rem;
    margin: 0;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.heart-icon {
    color: #ff6b6b;
    font-size: 0.75rem;
}


/* Responsive Styles */

@media (max-width: 992px) {
    .footer-top {
        grid-template-columns: 1fr 1fr;
    }
    .newsletter {
        grid-column: span 2;
        margin-top: 2rem;
    }
}

@media (max-width: 768px) {
    .footer-top {
        grid-template-columns: 1fr;
        gap: 2.5rem;
        padding: 3rem 0 2rem;
    }
    .footer-links {
        grid-template-columns: repeat(2, 1fr);
    }
    .newsletter {
        grid-column: span 1;
    }
}

@media (max-width: 576px) {
    .footer-links {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .social-links {
        margin-top: 1.5rem;
    }
    .footer-top {
        gap: 2rem;
        padding: 2.5rem 0 1.5rem;
    }
    .footer-container {
        padding: 0 1rem;
    }
}