/* Splash Screen Styles */

.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, #500157, #7b0282);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    overflow: hidden;
    will-change: transform;
}

.splash-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    z-index: 2;
    width: 100%;
    max-width: 500px;
}

.splash-logo-container {
    margin-bottom: 20px;
    animation: floatAnimation 3s ease-in-out infinite;
}

.splash-logo {
    width: 120px;
    height: auto;
    filter: drop-shadow(0 4px 15px rgba(0, 0, 0, 0.2));
}

.splash-title {
    font-size: 42px;
    font-weight: 800;
    color: white;
    margin: 0 0 10px;
    letter-spacing: 2px;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.splash-tagline {
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    margin: 0 0 30px;
    letter-spacing: 1px;
    font-style: italic;
}

.splash-loader {
    height: 3px;
    background: white;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    width: 0;
    max-width: 200px;
}

.splash-pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.05;
    z-index: 1;
    animation: patternMove 40s linear infinite;
}

@keyframes patternMove {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 1000px 1000px;
    }
}


/* Mobile Enhancements */

@media (max-width: 480px) {
    .splash-screen {
        background: linear-gradient(135deg, #500157, #7b0282);
    }
    .splash-logo {
        width: 100px;
    }
    .splash-title {
        font-size: 36px;
        margin-bottom: 8px;
    }
    .splash-tagline {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .splash-loader {
        height: 4px;
        max-width: 180px;
    }
    /* Add a subtle pulse animation to the logo on mobile */
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
    .splash-logo-container {
        animation: pulse 2s infinite ease-in-out;
    }
}


/* Extra small devices */

@media (max-width: 360px) {
    .splash-title {
        font-size: 32px;
    }
    .splash-tagline {
        font-size: 14px;
    }
    .splash-logo {
        width: 90px;
    }
}


/* Animation for the splash screen exit */

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}


/* Add a subtle shimmer effect to the title */

.splash-title {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.8) 100%);
    background-size: 200% auto;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shimmer 2s linear infinite;
}

@keyframes shimmer {
    to {
        background-position: 200% center;
    }
}

@keyframes floatAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}