/* RegionSelector.module.css */

.regionSelector {
    position: relative;
    display: inline-block;
}

.selectorButton {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
}

.selectorButton:hover {
    background-color: #e9e9e9;
}

.dropdown {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 16px;
    width: 280px;
    z-index: 1000;
}

.section {
    margin-bottom: 16px;
}

.sectionTitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
}

.select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f9f9f9;
    cursor: pointer;
}

.select:focus {
    outline: none;
    border-color: #500157;
}

.closeButton {
    background-color: #500157;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.2s ease;
}

.closeButton:hover {
    background-color: #3a0140;
}


/* Mini selector styles for header/footer */

.miniSelector {
    position: relative;
    display: inline-block;
}

.miniButton {
    background: none;
    border: none;
    color: #666;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.miniButton:hover {
    color: #500157;
}

.miniDropdown {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 12px;
    width: 200px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.miniSelect {
    width: 100%;
    padding: 6px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 12px;
    background-color: #f9f9f9;
}

.miniCloseButton {
    background: none;
    border: none;
    color: #999;
    font-size: 16px;
    cursor: pointer;
    align-self: flex-end;
    padding: 4px;
    margin-top: 4px;
}

.miniCloseButton:hover {
    color: #500157;
}


/* Responsive styles */

@media (max-width: 768px) {
    .dropdown {
        width: 260px;
    }
}

@media (max-width: 576px) {
    .dropdown {
        width: 240px;
        right: -70px;
    }
    .dropdown::before {
        content: '';
        position: absolute;
        top: -8px;
        right: 80px;
        width: 16px;
        height: 16px;
        background-color: white;
        transform: rotate(45deg);
        box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.05);
    }
    .miniDropdown {
        width: 180px;
    }
}