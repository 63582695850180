/* Modern Cart Page Styles */

.cartPage {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #f9f9f9;
}

.cartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.cartTitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin: 0;
    display: flex;
    align-items: center;
}

.cartCount {
    background-color: #500157;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    box-shadow: 0 2px 4px rgba(80, 1, 87, 0.2);
}

.continueShoppingLink {
    color: #500157;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: color 0.2s ease;
    font-size: 0.875rem;
    background-color: rgba(80, 1, 87, 0.08);
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
}

.continueShoppingLink:hover {
    color: #3a0140;
    background-color: rgba(80, 1, 87, 0.12);
}

.continueShoppingIcon {
    margin-right: 0.5rem;
}

.cartContent {
    display: grid;
    grid-template-columns: 1fr 350px;
    gap: 1.5rem;
}

.cartItems {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.cartItemsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.cartItem {
    display: grid;
    grid-template-columns: 120px 1fr auto;
    gap: 1.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    transition: all 0.2s ease;
}

.cartItem:hover {
    background-color: #fafafa;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.cartItem:last-child {
    border-bottom: none;
}

.productImage {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.cartItem:hover .productImage {
    transform: scale(1.05);
}

.productDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.productName {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 0.5rem 0;
    line-height: 1.4;
}

.productMeta {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
}

.productSize,
.productColor {
    font-size: 0.75rem;
    color: #666;
    background-color: #f5f5f5;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
}

.productPrice {
    font-size: 1.125rem;
    font-weight: 600;
    color: #500157;
    margin: 0.25rem 0 0.5rem;
}

.quantityControls {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 0.25rem;
    width: fit-content;
}

.quantityButton {
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
    color: #333;
    transition: all 0.2s ease;
    border-radius: 4px;
}

.quantityButton:hover {
    background-color: #e0e0e0;
}

.quantityButton:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.quantityValue {
    width: 32px;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
}

.itemActions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    height: auto;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.removeButton {
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.removeButton:hover {
    background-color: #fff0f0;
    color: #ff4d4f;
}

.itemTotal {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.cartSummary {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    position: sticky;
    top: 2rem;
    height: fit-content;
}

.summaryTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 1.5rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f0f0f0;
}

.summaryRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.summaryLabel {
    font-size: 0.875rem;
    color: #666;
}

.summaryValue {
    font-size: 0.875rem;
    font-weight: 500;
    color: #333;
}

.totalRow {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0;
    padding-top: 1rem;
    border-top: 1px solid #f0f0f0;
}

.totalLabel {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
}

.totalValue {
    font-size: 1.25rem;
    font-weight: 700;
    color: #500157;
}

.checkoutButton {
    background-color: #500157;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    box-shadow: 0 4px 8px rgba(80, 1, 87, 0.2);
}

.checkoutButton:hover {
    background-color: #3a0140;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(80, 1, 87, 0.25);
}

.emptyCart {
    text-align: center;
    padding: 3rem 1.5rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.emptyCartTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin: 1.5rem 0 1rem;
}

.emptyCartMessage {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
}

.shopNowButton {
    background-color: #500157;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0 4px 8px rgba(80, 1, 87, 0.2);
}

.shopNowButton:hover {
    background-color: #3a0140;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(80, 1, 87, 0.25);
}

.animationContainer {
    max-width: 250px;
    margin: 0 auto;
}


/* Responsive styles */

@media (max-width: 992px) {
    .cartContent {
        grid-template-columns: 1fr;
    }
    .cartSummary {
        position: static;
        margin-top: 1.5rem;
    }
}

@media (max-width: 768px) {
    .cartPage {
        padding: 1rem;
    }
    .cartHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    .cartItem {
        grid-template-columns: 100px 1fr;
        padding: 1rem;
        gap: 1rem;
        position: relative;
        padding-bottom: 2.5rem;
    }
    .itemActions {
        position: absolute;
        top: 1rem;
        right: 1rem;
        height: auto;
    }
    .itemTotal {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        margin-top: 0;
        font-size: 0.9rem;
    }
    .productImage {
        height: 100px;
    }
    .productDetails {
        min-height: 100px;
    }
    .productName {
        font-size: 0.9rem;
        padding-right: 2.5rem;
        margin-bottom: 0.25rem;
    }
    .productPrice {
        font-size: 1rem;
        margin-bottom: 0.25rem;
    }
    .productMeta {
        margin-bottom: 1.5rem;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
    }
    .productSize,
    .productColor {
        font-size: 0.7rem;
        padding: 0.2rem 0.4rem;
    }
    .quantityControls {
        margin-top: 0.75rem;
        position: absolute;
        bottom: 0.75rem;
        left: 100px;
        margin-left: 1rem;
    }
}

@media (max-width: 576px) {
    .cartPage {
        padding: 0.75rem;
        background-color: white;
    }
    .cartHeader {
        padding: 0 0.5rem;
    }
    .cartTitle {
        font-size: 1.25rem;
    }
    .continueShoppingLink {
        font-size: 0.75rem;
        padding: 0.4rem 0.6rem;
    }
    .cartItems {
        box-shadow: none;
        border-radius: 0;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
    }
    .cartSummary {
        box-shadow: none;
        border-radius: 0;
        padding: 1rem;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
    }
    .productDetails {
        min-height: 90px;
    }
    .productName {
        font-size: 0.85rem;
        line-height: 1.3;
    }
    .productPrice {
        font-size: 0.9rem;
    }
    .productMeta {
        flex-direction: row;
        gap: 0.4rem;
        margin-bottom: 1rem;
    }
    .cartItem {
        grid-template-columns: 80px 1fr;
        gap: 0.75rem;
        padding: 0.75rem;
        padding-bottom: 2.5rem;
    }
    .productImage {
        height: 80px;
    }
    .quantityControls {
        left: 80px;
    }
    .removeButton {
        width: 28px;
        height: 28px;
        padding: 0.3rem;
    }
    .emptyCart {
        padding: 2rem 1rem;
        box-shadow: none;
    }
    .emptyCartTitle {
        font-size: 1.25rem;
    }
    .emptyCartMessage {
        font-size: 0.875rem;
    }
    .shopNowButton {
        padding: 0.6rem 1.2rem;
        font-size: 0.875rem;
    }
    .animationContainer {
        max-width: 200px;
    }
    .itemTotal {
        font-size: 0.85rem;
    }
}