/* Contact Page Styles */

.contact-page {
    box-sizing: border-box;
    margin: 0 auto;
    overflow-x: hidden;
    color: #333;
    font-family: 'Montserrat', sans-serif;
}


/* Hero Section */

.contact-hero {
    position: relative;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80');
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 2rem 1rem;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(80, 1, 87, 0.7));
    z-index: 1;
}

.contact-hero-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    padding: 0 1rem;
}

.contact-hero-content h1 {
    font-size: 3.5rem;
    margin: 0.5rem 0;
    font-weight: 700;
    letter-spacing: 1px;
}

.contact-hero-content p {
    font-size: 1.2rem;
    margin: 1.5rem 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
}

.section-badge {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}


/* Container */

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
}


/* Contact Info Cards Section */

.contact-info-section {
    padding: 4rem 0;
    background-color: #f9f9f9;
}

.contact-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: -6rem;
}

.contact-card {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.card-icon {
    width: 70px;
    height: 70px;
    background-color: #f5f0ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    font-size: 1.8rem;
    color: #500157;
}

.contact-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #500157;
}

.contact-card p {
    margin: 0.5rem 0;
    font-size: 1.1rem;
    color: #333;
}

.card-subtitle {
    font-size: 0.9rem !important;
    color: #777 !important;
    margin-top: 0.5rem;
}


/* Contact Form Section */

.contact-form-section {
    padding: 5rem 0;
    background-color: white;
}

.form-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: start;
}

.form-content {
    padding-right: 2rem;
}

.form-content .section-badge {
    background-color: #f5f0ff;
    color: #500157;
}

.form-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #500157;
}

.form-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #555;
}

.brand-quote {
    background-color: #f9f9f9;
    border-left: 4px solid #500157;
    padding: 1.5rem;
    margin-top: 2rem;
}

.brand-quote p {
    margin: 0;
    font-size: 1.1rem;
    color: #555;
}

.form-container {
    background-color: white;
    border-radius: 10px;
    padding: 2.5rem;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
}

.form-group input,
.form-group textarea {
    padding: 0.8rem 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: inherit;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #500157;
}

.submit-button {
    background-color: #500157;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: background-color 0.3s;
    margin-top: 1rem;
    align-self: flex-start;
}

.submit-button:hover {
    background-color: #3e0143;
}

.form-error-message {
    background-color: #fff0f0;
    color: #e74c3c;
    padding: 0.8rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.form-success {
    text-align: center;
    padding: 2rem;
}

.form-success svg {
    font-size: 3rem;
    color: #27ae60;
    margin-bottom: 1rem;
}

.form-success h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
}

.form-success p {
    color: #555;
    font-size: 1.1rem;
}


/* FAQ Section */

.faq-section {
    padding: 5rem 0;
    background-color: #f9f9f9;
}

.faq-content {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
}

.faq-content .section-badge {
    background-color: #f5f0ff;
    color: #500157;
}

.faq-content h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #500157;
}

.faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2rem;
    text-align: left;
}

.faq-item {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.faq-item h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: #500157;
}

.faq-item p {
    color: #555;
    line-height: 1.6;
}


/* Mobile WhatsApp Button */

.mobile-whatsapp-btn {
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 0.8rem 1.5rem;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.3s ease;
}

.mobile-whatsapp-btn svg {
    font-size: 1.2rem;
}


/* Responsive Styles - Enhanced for Mobile */

@media (max-width: 1024px) {
    .contact-hero-content h1 {
        font-size: 3rem;
    }
    .form-content-wrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .form-content {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .contact-hero {
        min-height: 50vh;
    }
    .contact-hero-content h1 {
        font-size: 2.5rem;
    }
    .contact-hero-content p {
        font-size: 1rem;
        width: 100%;
    }
    .form-row {
        grid-template-columns: 1fr;
    }
    .faq-grid {
        grid-template-columns: 1fr;
    }
    .contact-cards {
        margin-top: -4rem;
        gap: 1.5rem;
    }
    .contact-info-section {
        padding: 3rem 0 2rem;
    }
    .contact-form-section,
    .faq-section {
        padding: 3rem 0;
    }
    .faq-content h2 {
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .contact-page {
        overflow-x: hidden;
    }
    .contact-hero {
        min-height: 40vh;
        padding: 3rem 0;
    }
    .contact-hero-content {
        width: 100%;
    }
    .contact-hero-content h1 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }
    .contact-hero-content p {
        font-size: 0.95rem;
        margin: 1rem 0;
    }
    .section-badge {
        font-size: 0.8rem;
        padding: 0.4rem 0.8rem;
    }
    .contact-container {
        padding: 0 1rem;
    }
    .contact-cards {
        margin-top: -3rem;
        grid-template-columns: 1fr;
    }
    .contact-card {
        padding: 1.5rem;
        margin: 0 0.5rem;
    }
    .card-icon {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .contact-card h3 {
        font-size: 1.3rem;
    }
    .contact-card p {
        font-size: 1rem;
    }
    .form-container {
        padding: 1.5rem;
        margin: 0 0.5rem;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
    }
    .form-content h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
    .form-content p {
        font-size: 0.95rem;
        margin-bottom: 1.5rem;
    }
    .brand-quote {
        padding: 1rem;
        margin-top: 1.5rem;
    }
    .brand-quote p {
        font-size: 0.95rem;
    }
    .form-group label {
        font-size: 0.9rem;
    }
    .form-group input,
    .form-group textarea {
        padding: 0.7rem;
        font-size: 1rem;
        border-radius: 4px;
    }
    .submit-button {
        width: 100%;
        padding: 0.9rem;
        font-size: 0.95rem;
        border-radius: 4px;
    }
    .faq-item {
        padding: 1.5rem;
        margin: 0 0.5rem;
    }
    .faq-item h3 {
        font-size: 1.1rem;
    }
    .faq-item p {
        font-size: 0.9rem;
    }
    .form-success svg {
        font-size: 2.5rem;
    }
    .form-success h3 {
        font-size: 1.5rem;
    }
    .form-success p {
        font-size: 0.95rem;
    }
    /* Improve touch targets for mobile */
    .form-group input,
    .form-group textarea,
    .submit-button {
        min-height: 48px;
        /* Minimum touch target size */
    }
    /* Add sticky form submit button for mobile */
    .contact-form {
        padding-bottom: 0.5rem;
    }
    /* Enhanced mobile styles */
    .mobile-whatsapp-btn {
        width: 80%;
        margin: 1.5rem auto 0;
    }
    /* Improve form usability on mobile */
    .form-group input,
    .form-group textarea {
        -webkit-appearance: none;
        /* Remove iOS default styling */
        font-size: 16px;
        /* Prevent zoom on iOS */
    }
    /* Add active state for better touch feedback */
    .contact-card:active,
    .faq-item:active,
    .submit-button:active {
        transform: scale(0.98);
    }
    /* Improve spacing between sections */
    .contact-info-section,
    .contact-form-section,
    .faq-section {
        margin-bottom: 1rem;
    }
    /* Make form elements more touch-friendly */
    .form-group {
        margin-bottom: 1rem;
    }
    .form-group label {
        margin-bottom: 0.4rem;
    }
    /* Add subtle visual feedback for form fields */
    .form-group input:focus,
    .form-group textarea:focus {
        box-shadow: 0 0 0 2px rgba(80, 1, 87, 0.2);
    }
}


/* Extra small devices */

@media (max-width: 360px) {
    .contact-hero-content h1 {
        font-size: 1.8rem;
    }
    .contact-card {
        padding: 1.2rem;
    }
    .card-icon {
        width: 50px;
        height: 50px;
    }
    .form-container,
    .faq-item {
        padding: 1.2rem;
    }
    .mobile-whatsapp-btn {
        width: 90%;
        font-size: 0.9rem;
        padding: 0.7rem 1.2rem;
    }
}