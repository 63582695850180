.shop {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.filter {
    width: 20%;
}

.filter h2 {
    color: #500157;
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.shopCont {
    width: 90%;
}

@media (max-width: 767px) {
    .shopCont {
        width: 100%;
    }
    .shop {
        display: flex;
        align-items: center;
    }
}

.shopCont .h2 {
    color: #500157;
    font-family: "Montserrat", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.shop .colPro {
    width: 289px;
    cursor: pointer;
}

.shop .colPro img {
    width: 100%;
}

.shop .coll {
    justify-content: flex-start;
    flex-wrap: wrap;
}

@media (max-width:500px) {
    .h2 {
        font-size: 20px;
    }
}


/* New styles for the shop page */

.shop-controls-container {
    position: relative;
    z-index: 100;
}

.filter-button,
.sort-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #f8f9fa;
    border: 1px solid #e2e2e2;
    color: #333;
    padding: 10px 16px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.filter-button:hover,
.sort-button:hover {
    background-color: #e9ecef;
}

.sort-button.active {
    background-color: #500157;
    color: white;
}

.sort-button.active:hover {
    background-color: #3e0143;
}

.sort-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 220px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 5px;
    z-index: 1000;
    transition: all 0.3s ease;
}

.sort-option {
    padding: 12px 16px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.2s ease;
}

.sort-option:hover {
    background-color: #f8f9fa;
}

.sort-option.active {
    color: #500157;
    background-color: rgba(80, 1, 87, 0.05);
    font-weight: 600;
}

.sort-option.active:hover {
    background-color: rgba(80, 1, 87, 0.1);
}

.product-count {
    font-size: 14px;
    color: #666;
}

.filter-section {
    background-color: #f8f9fa;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.filter-header h5 {
    margin: 0;
    font-weight: 600;
}

.filter-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.filter-option {
    margin-bottom: 15px;
}

.reset-button {
    background-color: #f8f9fa;
    border: 1px solid #e2e2e2;
    color: #333;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
}

.reset-button:hover {
    background-color: #e9ecef;
}

@media (max-width: 768px) {
    .shop-controls {
        flex-direction: column;
        align-items: flex-start;
    }
    .filter-button,
    .sort-button {
        width: 100%;
    }
    .sort-dropdown {
        width: 100%;
        right: auto;
        left: 0;
    }
}