@import url("https://fonts.googleapis.com/css2?family=Montserrat:family=Roboto:family=Rubik:display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

/* Collection Section Base Styles */

.collection-section {
    margin: 6rem 0;
    position: relative;
}

.section-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto 3rem;
    position: relative;
}

.section-header-content {
    max-width: 600px;
}

.section-badge {
    display: inline-block;
    background-color: rgba(80, 1, 87, 0.2);
    color: #500157;
    padding: 8px 16px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.section-badge::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    transform: translateX(-100%);
    animation: shimmer 3s infinite;
}

.section-badge.gold {
    background-color: rgba(245, 180, 10, 0.25);
    color: #d99b00;
    font-weight: 700;
    border: 1px solid rgba(245, 180, 10, 0.3);
}

.section-heading {
    font-size: 3rem;
    font-weight: 700;
    color: #500157;
    margin: 0 0 1rem;
    line-height: 1.1;
    position: relative;
    display: inline-block;
}

.section-heading::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 80px;
    height: 4px;
    background: linear-gradient(to right, #500157, rgba(80, 1, 87, 0.3));
    border-radius: 2px;
}

.section-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    margin: 1.5rem 0 0;
    max-width: 90%;
}

.section-navigation {
    display: flex;
    gap: 12px;
}

.nav-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: #500157;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.nav-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(80, 1, 87, 0.1) 0%, transparent 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.nav-button:hover {
    background-color: #500157;
    color: white;
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(80, 1, 87, 0.2);
}

.nav-button:hover::before {
    opacity: 1;
}

.nav-button:active {
    transform: translateY(-2px);
}

.bestsellers-section .nav-button:hover {
    background-color: #f5b40a;
    box-shadow: 0 10px 20px rgba(245, 180, 10, 0.2);
}


/* Products Showcase */

.products-showcase {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
}

.product-card {
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    position: relative;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
}

.product-image-container {
    position: relative;
    overflow: hidden;
    height: 280px;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.product-card:hover .product-image {
    transform: scale(1.05);
}

.product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.product-card:hover .product-overlay {
    opacity: 1;
}

.overlay-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.overlay-top {
    display: flex;
    justify-content: space-between;
}

.product-category,
.product-status {
    font-size: 12px;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #500157;
}

.quick-view-btn {
    background-color: #500157;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;
    width: fit-content;
    align-self: center;
}

.quick-view-btn:hover {
    background-color: #3e0143;
}

.color-options {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.color-option {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.color-option:hover {
    transform: scale(1.2);
}

.product-info {
    padding: 15px;
}

.product-name {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-price {
    font-size: 18px;
    font-weight: 700;
    color: #500157;
}

.product-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #500157;
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 4px;
    z-index: 2;
}

.new-tag {
    background: linear-gradient(135deg, #500157, #7b0282);
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    transition: all 0.3s ease;
}

.tag-icon {
    font-size: 10px;
    animation: sparkle 1.5s infinite;
}

@keyframes sparkle {
    0% {
        opacity: 0.7;
        transform: scale(0.8);
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 0.7;
        transform: scale(0.8);
    }
}

.product-card:hover .new-tag {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.product-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f5b40a;
    color: #500157;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 12px;
    border-radius: 4px;
    z-index: 2;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.3s ease;
}

.product-card:hover .product-badge {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background-color: #ffbf00;
}

.badge-icon {
    font-size: 10px;
    margin-right: 2px;
}

.skeleton-grid {
    opacity: 0.7;
}

.general {
    max-width: 100%;
    overflow-x: hidden;
}

.home {
    background: #500157;
    width: 100%;
    height: 100%;
}

.homeContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 2em auto;
}

.homeContent .text {
    width: 60%;
}

.homeContent .text h1 {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 65px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 0.7em 0;
    width: 70%;
}

.homeContent .text h2 {
    color: #f5b40a;
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 1em 0;
}

.homeContent .text h3 {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.hoImg {
    width: 40%;
    height: 90%;
}

.hoImg img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.product {
    margin: 2em auto;
}

.product h2 {
    color: #000;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 0.4em;
}

.product .ab {
    color: #726e6e;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 90%;
    margin: auto;
}

.proCon .rotating-container {
    position: relative;
    display: inline-block;
    transition: transform 0.5s ease, top 0.5s ease, height 0.5s ease;
    margin: 0 5px;
    /* Add some spacing between images */
}

.proCon .rotating-container img {
    max-width: 100%;
    /* Ensure images don't exceed their container */
    height: auto;
    display: block;
}

.proImg {
    margin: 4em auto 2em;
}

.proCon {
    display: flex;
    justify-content: center;
    overflow-x: auto;
    /* Allow horizontal scrolling on small screens */
}

.h2Name {
    font-size: 16px !important;
}

.proTog {
    /* width: 220px; */
    margin: 2em 0 2em auto;
    display: flex;
    align-items: center;
    padding-right: 2%;
    justify-content: right;
    gap: 2em;
}

.left {
    border-radius: 50%;
    border: 1px solid #ff9b05;
    background: #fff;
    width: 47px;
    height: 44px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff9b05;
}

.leftB {
    border-radius: 50%;
    border: 1px solid #ff9b05;
    background: #fff;
    width: 47px;
    height: 44px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff9b05;
}

.proIcon {
    font-size: 30px;
}

.right {
    border-radius: 50%;
    border: 1px solid #fff;
    background: #ff9b05;
    width: 47px;
    height: 44px;
    flex-shrink: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightB {
    border-radius: 50%;
    border: 1px solid #fff;
    background: #ff9b05;
    width: 47px;
    height: 44px;
    flex-shrink: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coll {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    /* gap: 1em; */
}

.colPro {
    width: 300px;
    border-radius: 25px;
    /* background: #f3f3f3; */
    height: 426px;
    margin-right: 10px;
}

.colPro h2 {
    color: #500157;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.bra {
    margin: 2em auto;
}

.brandTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto 2em;
}

.brandTop h2 {
    color: #000;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.brandTop p {
    color: #ff9b05;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.brand {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    background: #eef8ff;
    padding: 2em 0;
}

.brand img {
    width: 20%;
}

.comopt {
    flex-wrap: wrap;
    display: flex;
    width: 90%;
    margin: 2em auto;
    align-items: center;
    justify-content: center;
}

.comImg {
    position: relative;
    width: 60%;
    height: 400px;
}

.cm1 {
    position: absolute;
    top: 10%;
    left: 0%;
}

.cm2 {
    position: absolute;
    bottom: 20%;
    left: 10%;
}

.cm3 {
    position: absolute;
    top: 0%;
    right: 30%;
}

.cm4 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20%;
}

.cm5 {
    position: absolute;
    bottom: 0%;
    right: 40%;
}

.comText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
}

.dot {
    width: 5px;
    height: 5px;
    flex-shrink: 0;
    border-radius: 100%;
    flex-shrink: 0;
    background-color: #500157;
}

.dots {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.4em;
}

.dott {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    flex-shrink: 0;
    background-color: #500157;
}

.ttest h5 {
    color: #000;
    font-family: "Rubik", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin: 0 0 0.2em 0;
}

.ttest h3 {
    color: #500157;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin: 0 0 1em 0;
}

.ttest h4 {
    text-align: left;
    color: #726e6e;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 0.6em 0;
    line-height: normal;
    width: 243px;
}

.sub {
    border-radius: 50px;
    background: #500157;
    padding: 20px 0;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sub h3 {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 1em 0 0.3em;
}

.sub p {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 593px;
    margin: 0em 0 3em;
}

.input {
    border-radius: 25px;
    background: #fff;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 3em;
    margin: 0 0 2em;
}

.inpu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
}

.input input {
    color: #a1a1a1;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
}

.input button {
    padding: 10px 20px;
    background: rgba(80, 1, 87, 1);
    width: 10em;
    color: #fff;
    font-size: 20px;
    outline: none;
    border-radius: 25px;
    border: none;
}

.inIcon {
    color: #a1a1a1;
    font-size: 30px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.rotating-container {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    /* Keep the element at 100% opacity after the animation */
    animation-timing-function: ease-in-out;
}


/* Example of staggering the animations using nth-child */

.rotating-container:nth-child(1) {
    animation-delay: 0s;
}

.rotating-container:nth-child(2) {
    animation-delay: 1s;
}

.rotating-container:nth-child(3) {
    animation-delay: 2s;
}

.rotating-container:nth-child(4) {
    animation-delay: 3s;
}

.rotating-container:nth-child(5) {
    animation-delay: 4s;
}

@media (max-width: 1200px) {
    .homeContent .text h1 {
        font-size: 50px;
    }
}

@media (max-width: 1000px) {
    .homeContent .text h1 {
        font-size: 40px !important;
    }
}

@media (max-width: 800px) {
    .homeContent .text h1 {
        font-size: 35px;
        margin: 0;
    }
    .text h2 {
        font-size: 25px !important;
        margin: 0 !important;
    }
    .text h3 {
        font-size: 18px !important;
        margin: 0;
    }
    .text {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
    }
}

@media (max-width: 700px) {
    .home {
        padding: 10px 0;
    }
    .homeContent .text h1 {
        font-size: 25px !important;
        margin: 0;
    }
    .text h2 {
        font-size: 15px !important;
        margin: 0 !important;
    }
    .text h3 {
        font-size: 14px !important;
        margin: 0;
    }
    .text {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
    }
    .product .ab {
        font-size: 16px;
    }
    .product h2 {
        font-size: 16px;
    }
    .input {
        width: 100% !important;
    }
    .inpu {
        width: unset;
    }
    .inpu svg {
        display: none;
    }
}

@media (max-width: 500px) {
    .input input {
        width: 100%;
    }
    .sub h3 {
        font-size: 28px;
        text-align: center;
    }
}

@media (max-width: 900px) {
    .subscribe {
        display: none;
    }
    .sub p {
        width: 95%;
    }
    .sub h3 {
        font-size: 25px;
    }
    .input button {
        width: unset;
        padding: 1em;
    }
    .sub {
        padding: 20px;
        width: 90%;
    }
    .input {
        justify-content: space-between;
        padding: 3em 3px;
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .rotating-container img {
        width: 85%;
    }
}

.mobile-image {
    width: 90%;
    height: 400px !important;
    /* Allows the height to adjust proportionally based on the image's aspect ratio */
    display: block;
    /* Ensures that the image is centered within its container */
    margin: 0 auto;
    /* Centers the image horizontally */
    object-fit: contain;
}

.homeMobile {
    background-image: url("../assets/image/bg90.jpeg");
    height: 75vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.shopfor {
    font-size: 20px;
    font-family: "light";
    color: #ff9b05;
    /* border: 1px solid red ; */
    background-color: #500157;
    border: none;
    padding: 10px;
}

.shopWant {
    text-decoration: none;
    padding-top: 20em;
    padding-left: 1.5em;
}

.shopWant p {
    font-size: 25px;
    color: #fff;
    font-family: "light";
    margin: 0;
}

.proPri {
    display: flex;
    /* align-items: flex-start;
  justify-content: flex-start; */
    flex-direction: column;
}

.proPri p {
    color: #ff9b05;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
}

.proPri button {
    border-radius: 10px;
    background: #500157;
    width: 97px;
    height: 35px;
    flex-shrink: 0;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
    border: none;
}

@media (max-width: 768px) {
    .proTog {
        margin: 0;
    }
    .coll {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    .colPro {
        width: 100%;
        height: auto;
        margin-bottom: 5em;
    }
    .colPro img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    .left {
        border-radius: 50%;
        border: 1px solid #fff;
        background: #500157;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        right: 19.2rem;
        top: 10rem;
    }
    .leftB {
        border-radius: 50%;
        border: 1px solid #fff;
        background: #500157;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        right: 19.2rem;
        top: 7rem;
    }
    .proIcon {
        font-size: 30px;
    }
    .right {
        border-radius: 50%;
        border: 1px solid #fff;
        background: #ff9b05;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        color: #fff;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        top: 10rem;
        left: 0.2rem;
    }
    .rightB {
        border-radius: 50%;
        border: 1px solid #fff;
        background: #ff9b05;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        color: #fff;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        top: 7rem;
        left: 0.2rem;
    }
    .colPro h2 {
        color: #500157;
        font-family: "Montserrat", sans-serif;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }
    .proPri {
        border-radius: 0;
        background: transparent;
        height: auto;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .proPri p {
        font-size: 13px;
        margin: 0;
        text-align: center;
        width: 90%;
    }
}

.newArr {
    font-family: "light";
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
}

.natureReady {
    background-image: url("../assets/image/27.png");
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 10em;
}

.natureReady h4 {
    color: #ff9b05;
    font-family: "light";
    text-align: center;
    font-weight: 900;
    font-size: 24px;
}

.natureReady p {
    color: #fff;
    font-size: 12px;
    text-align: center;
}

.natureRed {
    align-items: center;
    display: flex;
    justify-content: center;
}


/* Trendy Collection Redesign */


/* Common styles for both mobile and desktop */

.trendy-badge {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.15);
    color: #f5b40a;
    padding: 8px 16px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 15px;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.8s ease forwards, pulse-badge 2s infinite;
    position: relative;
    overflow: hidden;
}

.trendy-badge::after {
    content: '🔥';
    margin-left: 6px;
    font-size: 12px;
    animation: bounce 1s ease infinite;
    display: inline-block;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);
    }
}

.trendy-badge.desktop {
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
}

.trendy-shop-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #500157;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 14px 28px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(80, 1, 87, 0.3);
    position: relative;
    overflow: hidden;
}

.trendy-shop-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.6s ease;
}

.trendy-shop-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(80, 1, 87, 0.4);
    background-color: #3e0143;
}

.trendy-shop-btn:hover::before {
    left: 100%;
}

.trendy-shop-btn svg {
    transition: transform 0.3s ease;
}

.trendy-shop-btn:hover svg {
    transform: translateX(5px);
}

.trendy-shop-btn.desktop {
    margin-top: 30px;
}


/* Mobile styles */

.homeMobile {
    background-image: url("../assets/image/bg90.jpeg");
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.homeMobile::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(80, 1, 87, 0.7));
    z-index: 1;
}

.trendy-collection-container {
    position: relative;
    z-index: 2;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 90%;
}

.trendy-title {
    color: white;
    font-size: 3.5rem;
    font-weight: 700;
    margin: 0.5rem 0;
    line-height: 1.1;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.trendy-subtitle {
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
    opacity: 0.9;
    max-width: 80%;
    line-height: 1.5;
}

.trending-banner {
    width: 90%;
    max-width: 1400px;
    margin: 6rem auto;
    height: 450px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
}

.trending-content {
    width: 50%;
    padding: 4rem;
    background: linear-gradient(135deg, #500157 0%, #3a0140 100%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.limited-edition-badge {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    position: relative;
}

.trending-badge {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    padding: 8px 16px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.edition-year {
    font-size: 18px;
    font-weight: 700;
    color: #f5b40a;
    margin-left: 12px;
    position: relative;
    top: 1px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.trending-title {
    font-size: 3.2rem;
    font-weight: 700;
    margin: 0 0 1rem;
    line-height: 1.1;
    background: linear-gradient(to right, #ffffff, #f5b40a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.trending-description {
    font-size: 1.1rem;
    line-height: 1.7;
    margin: 0 0 2.5rem;
    opacity: 0.9;
    max-width: 90%;
}

.trending-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f5b40a;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    align-self: flex-start;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
}

.trending-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.6s ease;
}

.trending-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    background-color: #e6a700;
}

.trending-btn:hover::before {
    left: 100%;
}

.trending-btn svg {
    transition: transform 0.3s ease;
}

.trending-btn:hover svg {
    transform: translateX(5px);
}

.trending-image {
    width: 50%;
    background-image: url('https://images.unsplash.com/photo-1490481651871-ab68de25d43d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80');
    background-size: cover;
    background-position: center;
    position: relative;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(80, 1, 87, 0.8), rgba(0, 0, 0, 0.4));
}

.limited-edition-marker {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    z-index: 3;
    transform: rotate(-5deg);
    animation: pulse 2s infinite;
}

.marker-text {
    font-size: 14px;
    font-weight: 700;
    color: #500157;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.marker-year {
    font-size: 24px;
    font-weight: 800;
    color: #f5b40a;
    line-height: 1;
}

@keyframes pulse {
    0% {
        transform: rotate(-5deg) scale(1);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    }
    50% {
        transform: rotate(-5deg) scale(1.05);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
    }
    100% {
        transform: rotate(-5deg) scale(1);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    }
}


/* Animations */

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}


/* Responsive Styles for Trending Banner */

@media (max-width: 1200px) {
    .trending-banner {
        height: 400px;
    }
    .trending-content {
        padding: 3rem;
    }
    .trending-title {
        font-size: 2.8rem;
    }
    .limited-edition-marker {
        width: 100px;
        height: 100px;
        bottom: 20px;
        right: 20px;
    }
    .marker-text {
        font-size: 12px;
    }
    .marker-year {
        font-size: 20px;
    }
}

@media (max-width: 900px) {
    .trending-banner {
        flex-direction: column;
        height: auto;
    }
    .trending-content,
    .trending-image {
        width: 100%;
    }
    .trending-content {
        padding: 3rem 2rem;
        order: 2;
    }
    .trending-image {
        height: 300px;
        order: 1;
    }
    .image-overlay {
        background: linear-gradient(to bottom, transparent, rgba(80, 1, 87, 0.8));
    }
    .limited-edition-marker {
        top: 20px;
        right: 20px;
        bottom: auto;
    }
}

@media (max-width: 768px) {
    .trending-title {
        font-size: 2.2rem;
    }
    .trendy-subtitle {
        font-size: 1.1rem;
        max-width: 100%;
    }
    .trending-description {
        font-size: 1rem;
    }
    .trending-btn,
    .view-more-btn {
        padding: 14px 28px;
        font-size: 14px;
    }
    .limited-edition-marker {
        width: 90px;
        height: 90px;
    }
    .marker-text {
        font-size: 11px;
    }
    .marker-year {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .trending-content {
        padding: 2rem 1.5rem;
    }
    .trending-title {
        font-size: 1.8rem;
    }
    .trendy-title {
        font-size: 2.2rem;
    }
    .trendy-subtitle {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }
    .trending-image {
        height: 250px;
    }
    .limited-edition-badge {
        flex-direction: column;
        align-items: flex-start;
    }
    .edition-year {
        margin-left: 0;
        margin-top: 8px;
    }
    .limited-edition-marker {
        width: 80px;
        height: 80px;
        top: 15px;
        right: 15px;
    }
    .marker-text {
        font-size: 10px;
    }
    .marker-year {
        font-size: 16px;
    }
}


/* Responsive Styles for Collection Sections */

@media (max-width: 1200px) {
    .section-heading {
        font-size: 2.5rem;
    }
    .products-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }
    .product-image-container {
        height: 260px;
    }
}

@media (max-width: 900px) {
    .section-header-wrapper {
        flex-direction: column;
        gap: 2rem;
    }
    .section-navigation {
        align-self: flex-end;
    }
    .section-heading {
        font-size: 2.2rem;
    }
    .products-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    .product-image-container {
        height: 240px;
    }
}

@media (max-width: 768px) {
    .collection-section {
        margin: 4rem 0;
    }
    .section-badge {
        font-size: 12px;
        padding: 6px 12px;
    }
    .section-heading {
        font-size: 2rem;
    }
    .section-description {
        font-size: 1rem;
    }
    .nav-button {
        width: 45px;
        height: 45px;
    }
    .product-image-container {
        height: 220px;
    }
    .product-name {
        font-size: 14px;
    }
    .product-price {
        font-size: 16px;
    }
    .product-badge {
        font-size: 10px;
        padding: 3px 10px;
    }
}

@media (max-width: 480px) {
    .collection-section {
        margin: 3rem 0;
    }
    .section-header-wrapper {
        margin-bottom: 2rem;
    }
    .section-heading {
        font-size: 1.8rem;
    }
    .section-heading::after {
        width: 60px;
        height: 3px;
    }
    .section-description {
        font-size: 0.9rem;
    }
    .products-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    .nav-button {
        width: 40px;
        height: 40px;
    }
    .product-card {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }
    .product-image-container {
        height: 180px;
    }
    .product-overlay {
        display: none;
    }
    .product-badge,
    .product-tag,
    .new-tag {
        font-size: 8px;
        padding: 2px 6px;
    }
    .tag-icon,
    .badge-icon {
        font-size: 8px;
    }
    .product-name {
        font-size: 12px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .product-price {
        font-size: 14px;
        font-weight: 700;
    }
    .product-info {
        padding: 8px;
    }
    .product-meta {
        justify-content: center;
    }
    .quick-view-btn {
        display: none;
    }
}

@media (max-width: 480px) {
    .trendy-title {
        font-size: 2.2rem;
    }
    .trendy-subtitle {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }
}

.pulse-animation {
    animation: pulse-badge 2s infinite;
    background: linear-gradient(90deg, #7b0282, #c4008c);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 15px rgba(123, 2, 130, 0.4);
    position: relative;
    overflow: hidden;
    font-weight: 800;
    letter-spacing: 2px;
    padding: 10px 18px;
    transform: translateY(0);
    transition: transform 0.3s ease;
}

.pulse-animation::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shimmer-badge 2s infinite;
}

@keyframes shimmer-badge {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

.pulse-animation::after {
    content: '✨';
    margin-left: 6px;
    font-size: 12px;
    animation: sparkle 1.5s infinite;
    display: inline-block;
}

.pulse-animation:hover {
    transform: translateY(-2px);
}

@keyframes pulse-badge {
    0% {
        box-shadow: 0 0 0 0 rgba(245, 180, 10, 0.6);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(245, 180, 10, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(245, 180, 10, 0);
    }
}


/* View All Buttons Styling */

.section-cta {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

.view-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #500157;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 14px 28px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(80, 1, 87, 0.2);
    text-decoration: none;
    position: relative;
    overflow: hidden;
}

.view-more-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.6s ease;
}

.view-more-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(80, 1, 87, 0.3);
    background-color: #3e0143;
}

.view-more-btn:hover::before {
    left: 100%;
}

.view-more-btn svg {
    transition: transform 0.3s ease;
}

.view-more-btn:hover svg {
    transform: translateX(5px);
}

.view-more-btn.gold {
    background-color: #f5b40a;
    color: #500157;
    box-shadow: 0 4px 15px rgba(245, 180, 10, 0.2);
}

.view-more-btn.gold:hover {
    background-color: #e6a700;
    box-shadow: 0 8px 20px rgba(245, 180, 10, 0.3);
}


/* Responsive styles for view-more-btn */

@media (max-width: 768px) {
    .view-more-btn {
        padding: 12px 24px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .view-more-btn {
        padding: 10px 20px;
        font-size: 13px;
    }
}