/* Modern About Page Styles with Enhanced Design */

.about-page {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    color: #333;
    overflow-x: hidden;
    --primary-color: #500157;
    --primary-light: rgba(80, 1, 87, 0.1);
    --primary-gradient: linear-gradient(135deg, #500157 0%, #7b0282 100%);
    --accent-color: #ff6b6b;
    --text-color: #333;
    --light-text: #666;
    --bg-light: #f9f9f9;
    --white: #fff;
    --shadow-sm: 0 4px 6px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 10px 20px rgba(0, 0, 0, 0.08);
    --shadow-lg: 0 20px 40px rgba(0, 0, 0, 0.12);
    --transition: all 0.3s ease;
    --border-radius: 12px;
    --border-color: rgba(80, 1, 87, 0.15);
}


/* Container */

.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
    position: relative;
    z-index: 2;
}


/* Section Badge */

.section-badge {
    display: inline-block;
    background-color: var(--primary-light);
    color: var(--primary-color);
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.4rem 1rem;
    border-radius: 50px;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}


/* Hero Section with Image */

.about-hero {
    position: relative;
    height: 100vh;
    min-height: 600px;
    max-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    overflow: hidden;
}

.hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.hero-content {
    position: relative;
    z-index: 3;
    text-align: center;
    width: 100%;
}

.hero-text-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: rgba(80, 1, 87, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 12px;
}

.about-hero h1 {
    font-size: 4rem;
    font-weight: 700;
    margin: 0 0 1rem;
    letter-spacing: 2px;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.hero-tagline {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0 0 1.5rem;
    letter-spacing: 1px;
}

.hero-description {
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
    opacity: 0.9;
}

.scroll-indicator {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    color: var(--white);
    opacity: 0.7;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) translateX(-50%);
    }
    40% {
        transform: translateY(-20px) translateX(-50%);
    }
    60% {
        transform: translateY(-10px) translateX(-50%);
    }
}


/* About Section with Image */

.about-section {
    padding: 7rem 0;
    background-color: var(--white);
    position: relative;
}

.about-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(var(--primary-light) 1px, transparent 1px);
    background-size: 30px 30px;
    opacity: 0.3;
    z-index: 1;
}

.about-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    align-items: center;
    position: relative;
    z-index: 2;
}

.about-content {
    position: relative;
}

.about-content h2 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin: 0 0 1.5rem;
    position: relative;
    padding-bottom: 1rem;
    letter-spacing: -0.5px;
}

.about-content h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: var(--primary-color);
}

.about-content p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: var(--light-text);
    margin: 0;
    letter-spacing: 0.2px;
}

.content-accent {
    margin-top: 2rem;
    display: inline-block;
    position: relative;
}

.content-accent span {
    font-size: 0.85rem;
    font-weight: 500;
    color: var(--primary-color);
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    border-left: 2px solid var(--primary-color);
    background-color: var(--primary-light);
    display: inline-block;
}

.about-image-container {
    position: relative;
    max-width: 450px;
    margin: 0 auto;
}

.about-image {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: var(--shadow-lg);
    z-index: 2;
    height: 400px;
    width: 100%;
}

.about-image.limited-edition {
    border: 1px solid rgba(80, 1, 87, 0.2);
    box-shadow: var(--shadow-md);
    height: 350px;
}

.about-image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    transition: transform 0.5s ease;
    filter: saturate(0.9);
}

.about-image:hover img {
    transform: scale(1.03);
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 50%;
    pointer-events: none;
}

.limited-badge {
    background-color: var(--primary-color);
    color: white;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.3rem 0.8rem;
    border-radius: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
}


/* Quote Section with Gradient Background */

.quote-section {
    background: var(--primary-gradient);
    color: var(--white);
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
}

.quote-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    z-index: 1;
}

.quote-content {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2;
}

.quote-content h2 {
    font-size: 2.5rem;
    margin: 0 0 2rem;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.quote-text {
    position: relative;
    padding: 2rem 3rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    backdrop-filter: blur(5px);
}

.quote-icon {
    font-size: 2rem;
    opacity: 0.3;
    position: absolute;
}

.quote-icon.left {
    top: 1rem;
    left: 1rem;
}

.quote-icon.right {
    bottom: 1rem;
    right: 1rem;
}

.quote-text p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin: 0;
    text-align: left;
}


/* Values Section with Hover Effects */

.values-section {
    padding: 7rem 0;
    background-color: var(--bg-light);
    position: relative;
}

.values-header {
    text-align: center;
    margin-bottom: 4rem;
    position: relative;
    z-index: 2;
}

.section-title {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin: 0 0 1rem;
    position: relative;
    padding-bottom: 1rem;
    display: inline-block;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background-color: var(--primary-color);
}

.values-intro {
    max-width: 600px;
    margin: 1rem auto 0;
    font-size: 1.1rem;
    color: var(--light-text);
}

.values-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    position: relative;
    z-index: 2;
}

.value-card {
    background-color: var(--white);
    border-radius: 12px;
    padding: 2.5rem 1.5rem;
    text-align: center;
    box-shadow: var(--shadow-md);
    transition: var(--transition);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.value-card:hover {
    transform: translateY(-10px);
    box-shadow: var(--shadow-lg);
    background-color: var(--primary-light);
}

.value-icon {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 2;
}

.value-card h3 {
    font-size: 1.25rem;
    margin: 0 0 1rem;
    color: var(--text-color);
    position: relative;
    z-index: 2;
}

.value-card p {
    font-size: 0.95rem;
    line-height: 1.6;
    color: var(--light-text);
    margin: 0;
    position: relative;
    z-index: 2;
}


/* Mission Section with Image */

.mission-section {
    padding: 7rem 0;
    background-color: var(--white);
    position: relative;
}

.mission-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    align-items: center;
    position: relative;
    z-index: 2;
}

.mission-image {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: var(--shadow-lg);
    z-index: 2;
    height: 450px;
}

.mission-image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.mission-image:hover img {
    transform: scale(1.05);
}

.mission-content {
    position: relative;
    z-index: 2;
}

.mission-content h2 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin: 0 0 2rem;
    position: relative;
    padding-bottom: 1rem;
}

.mission-content h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: var(--primary-color);
}

.gradient-text {
    font-size: 1.2rem;
    line-height: 1.8;
    margin: 0;
    background: var(--primary-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
}


/* Call to Action Section */

.cta-section {
    padding: 5rem 0;
    background-color: var(--bg-light);
    position: relative;
}

.cta-content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    background-color: var(--white);
    padding: 3rem;
    border-radius: 12px;
    box-shadow: var(--shadow-md);
}

.cta-content h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin: 0 0 1rem;
}

.cta-content p {
    font-size: 1.1rem;
    color: var(--light-text);
    margin: 0 0 2rem;
}

.cta-button {
    display: inline-block;
    background: var(--primary-gradient);
    color: var(--white);
    padding: 1rem 2rem;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    box-shadow: 0 4px 15px rgba(80, 1, 87, 0.3);
    transition: var(--transition);
}

.cta-button:hover {
    box-shadow: 0 6px 20px rgba(80, 1, 87, 0.4);
    color: var(--white);
}


/* Responsive Styles */

@media (max-width: 992px) {
    .about-hero {
        min-height: 500px;
    }
    .about-hero h1 {
        font-size: 3.5rem;
    }
    .hero-tagline {
        font-size: 1.5rem;
    }
    .hero-description {
        font-size: 1.1rem;
    }
    .values-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
    .about-section,
    .quote-section,
    .values-section,
    .mission-section,
    .cta-section {
        padding: 5rem 0;
    }
    .about-grid,
    .mission-grid {
        gap: 3rem;
    }
}

@media (max-width: 768px) {
    .about-hero {
        min-height: 400px;
    }
    .hero-text-container {
        padding: 1.5rem;
    }
    .about-hero h1 {
        font-size: 3rem;
    }
    .hero-tagline {
        font-size: 1.3rem;
    }
    .hero-description {
        font-size: 1rem;
    }
    .about-grid,
    .mission-grid {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .about-content,
    .mission-content {
        order: 2;
    }
    .about-image-container,
    .mission-image {
        order: 1;
    }
    .about-section,
    .quote-section,
    .values-section,
    .mission-section,
    .cta-section {
        padding: 4rem 0;
    }
    .quote-text {
        padding: 1.5rem 2rem;
    }
    .quote-text p {
        font-size: 1.1rem;
    }
    .mission-content,
    .cta-content {
        padding: 2rem;
    }
    .section-badge {
        font-size: 0.75rem;
    }
    .about-image,
    .mission-image {
        height: 350px;
    }
}

@media (max-width: 576px) {
    .about-hero {
        min-height: 350px;
    }
    .hero-text-container {
        padding: 1rem;
    }
    .about-hero h1 {
        font-size: 2.5rem;
    }
    .hero-tagline {
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }
    .hero-description {
        font-size: 0.9rem;
    }
    .about-content h2,
    .quote-content h2,
    .section-title,
    .mission-content h2,
    .cta-content h2 {
        font-size: 1.8rem;
    }
    .about-content p {
        font-size: 1rem;
    }
    .values-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    .quote-text {
        padding: 1rem 1.5rem;
    }
    .quote-icon {
        font-size: 1.5rem;
    }
    .quote-text p,
    .mission-content p,
    .gradient-text,
    .cta-content p {
        font-size: 1rem;
        line-height: 1.6;
    }
    .about-section,
    .quote-section,
    .values-section,
    .mission-section,
    .cta-section {
        padding: 3rem 0;
    }
    .mission-content,
    .cta-content {
        padding: 1.5rem;
    }
    .cta-button {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
    }
    .about-image,
    .mission-image {
        height: 250px;
    }
}