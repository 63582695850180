@import url("https://fonts.googleapis.com/css2?family=Montserrat:display=swap");
.nav {
    height: 40px;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em 1em 5em 1em !important;
    position: sticky;
    top: 0;
    background-color: #fff;
    /* Add a background color to the navigation */
    z-index: 100;
    /* Ensure the navigation appears above other elements */
}

.nav img {
    width: 70px;
    height: 50.667px;
}

.nav .list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.nav .list li {
    list-style: none;
}

.nav .list a {
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    transition: 0.3s ease;
}

.nav .list a:hover {
    color: #500157;
    transition: 0.3s ease;
}

.nav button {
    padding: 1em;
    flex-shrink: 0;
    border-radius: 6px;
    color: #500157;
    background: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
}


/* .nav-btn{
  display: none;
} */

.cartStyles {
    color: "White";
    text-decoration: "none";
}

@media (max-width: 760px) {
    .nav-btn {
        display: block;
        z-index: 5;
    }
    .nav {
        padding: 1em 1em 1em 1em !important;
    }
    /* .list.din {
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100vh;
    background-color: #324;
    color: #fff;
    padding: 20px;
    transition: left 0.3s ease;
    z-index: 10;
  } */
    .list.din.open {
        left: 0;
    }
    .list li {
        display: none;
    }
    .cartStyles {
        display: none;
    }
    .cartStyles.open {
        display: flex;
        z-index: 4;
        justify-content: center;
        align-items: flex-end;
        top: 255px;
        font-size: 20px;
        position: relative;
        color: #fff;
    }
    .list.din li {
        display: flex;
        color: #fff;
    }
    .list.din a {
        color: #fff;
        text-decoration: none;
    }
    /* .nav-close-btn {
    display: flex;
    position: absolute;
    top: 1.8em;
    right: 2em;
    z-index: 5;
    padding: 1em;
    flex-shrink: 0;
    border-radius: 6px;
    background: #500157;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
  } */
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
        display: none;
    }
    .overlay.open {
        display: block;
    }
    .logo {
        display: none;
    }
    .logoSide {
        height: 50px;
    }
}

.side-modal {
    position: fixed;
    top: 0;
    left: -320px;
    width: 320px;
    height: 100vh;
    background-color: white;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 1000;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #500157 #f1f1f1;
}

.side-modal::-webkit-scrollbar {
    width: 5px;
}

.side-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.side-modal::-webkit-scrollbar-thumb {
    background-color: #500157;
    border-radius: 10px;
}

.side-modal.open {
    left: 0;
}

.side-modal-content {
    padding: 25px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.side-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.logoSide {
    width: 80px;
    height: auto;
    transition: transform 0.3s ease;
}

.logoSide:hover {
    transform: scale(1.05);
}

.close-btn {
    background: none;
    border: none;
    font-size: 22px;
    cursor: pointer;
    color: #333;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.close-btn:hover {
    background-color: #f5f0ff;
    color: #500157;
}

.cart-close-btn {
    font-size: 26px;
    width: 46px;
    height: 46px;
    background-color: #f5f0ff;
}

.cart-close-btn:hover {
    background-color: #f0e5ff;
    transform: rotate(90deg);
}

.sidebar-divider {
    height: 1px;
    background: linear-gradient(to right, transparent, #e0e0e0, transparent);
    margin: 15px 0;
    width: 100%;
}


/* Cart Info Styling */

.cart-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-decoration: none;
    color: #333;
    transition: all 0.3s ease;
    border: none;
    margin: 5px 0;
}

.cart-info:hover {
    background-color: #f5f0ff;
    transform: translateY(-2px);
}

.cart-info-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cart-label {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.cart-count {
    background-color: #500157;
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}

.cart-icon {
    font-size: 20px;
    color: #500157;
}


/* Category Styling */

.sidebar-section {
    margin: 10px 0 20px;
}

.sidebar-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    padding-left: 10px;
}

.sidebar-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 18px;
    background-color: #500157;
    border-radius: 2px;
}

.sideTextContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sideText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    border-radius: 6px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    transition: all 0.25s ease;
    position: relative;
    overflow: hidden;
}

.sideText::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: #500157;
    transform: scaleY(0);
    transition: transform 0.25s ease;
}

.sideText:hover,
.sideText.active {
    background-color: #f5f0ff;
    color: #500157;
    font-weight: 500;
}

.sideText:hover::before,
.sideText.active::before {
    transform: scaleY(1);
}

.category-name {
    transition: transform 0.3s ease;
}

.sideText:hover .category-name {
    transform: translateX(5px);
}

.category-arrow {
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
    font-size: 18px;
}

.sideText:hover .category-arrow {
    opacity: 1;
    transform: translateX(0);
}

.no-categories {
    padding: 15px;
    text-align: center;
    color: #777;
    font-style: italic;
}


/* Quick Links */

.quick-links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.quick-link {
    padding: 8px 15px;
    background-color: #f9f9f9;
    border-radius: 20px;
    text-decoration: none;
    color: #333;
    font-size: 13px;
    transition: all 0.3s ease;
}

.quick-link:hover {
    background-color: #500157;
    color: white;
    transform: translateY(-2px);
}


/* Sidebar Footer */

.sidebar-footer {
    margin-top: auto;
    text-align: center;
    padding: 20px 0 10px;
}

.sidebar-footer p {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    color: #500157;
}

.sidebar-tagline {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #777 !important;
    margin-top: 5px !important;
    font-style: italic;
}


/* Overlay Enhancement */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 999;
    backdrop-filter: blur(2px);
}

.overlay.open {
    opacity: 1;
    visibility: visible;
}


/* Mobile Enhancements */

@media (max-width: 480px) {
    .side-modal {
        width: 85%;
        left: -85%;
    }
    .sidebar-title {
        font-size: 15px;
    }
    .sideText {
        padding: 10px 12px;
    }
    .cart-info {
        padding: 10px 12px;
    }
}

.side-modal h3 {
    margin-bottom: 10px;
}

.side-modal ul {
    list-style-type: none;
    padding: 0;
}

.side-modal li {
    margin-bottom: 10px;
}

.sideText {
    border-bottom: 1px solid #ccc;
    margin: 0;
    /* padding-bottom: 10px; */
    text-decoration: none;
    color: #3d3d3d;
    font-family: 'light';
    font-size: 12px;
}

.mobileCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.mobile-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
}

.mobile-cart span {
    color: #ff9b05;
    font-size: 18px;
}

.main-content {
    transition: opacity 0.3s ease;
}

.main-content.inactive {
    pointer-events: none;
    opacity: 0.5;
    overflow: hidden;
}

.dt {
    margin: 0;
    font-family: 'light';
    font-size: 26px;
    color: #ff9b05;
    text-decoration: none;
}

.dt span {
    color: #500157;
}

@media (min-width: 320px) {
    .dt {
        font-size: 26px;
    }
}


/* ... previous CSS code ... */

.cartModal {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.cartModal.open {
    opacity: 1;
    pointer-events: auto;
}

.cartModalContent {
    width: 335px;
    max-width: 100%;
    background-color: #fff;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cartModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding: 15px;
}

.productName,
.productPrice {
    font-size: 13px;
    margin: 0;
}

.productName {
    width: 200px;
}

.removeButton {
    position: relative;
    top: -5px;
    left: 15px;
    color: #000;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.productTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mycart {
    margin: 0;
    font-family: 'light';
    color: #ff9b05;
    font-size: 18px;
}

.mtcartlen {
    margin: 0;
    color: #989898;
    font-size: 10px;
}

.cartItemsWrapper {
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    overflow-y: auto;
    width: 100%;
}

.cartItemsContainer {
    flex: 1;
    /* overflow-y: auto; */
}

.cartItem {
    display: flex;
    border-bottom: 1px solid #500157;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
}

.cartItem img {
    width: 70px;
    height: 80px;
    object-fit: cover;
    margin-right: 10px;
}

.cartItem p {
    margin: 0;
}

.quantityButtons {
    display: flex;
    align-items: center;
    margin-top: 5px;
    gap: 10px;
}

.quantity {
    font-size: 14px;
}

.quantityButton {
    /* background-color: #500157; */
    color: #000;
    border: none;
    padding: 10px 8px;
    margin: 0 5px;
    cursor: pointer;
    /* font-size: ; */
}

.cartItemBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.cartTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subtotal,
.shiptext {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    margin: 0;
}

.total {
    font-size: 12px;
    font-weight: 200;
    color: #000000;
    margin: 0;
}

.shipping {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttomCart {
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
    padding: 10px;
}

.checkoutButton {
    justify-content: center;
    display: flex;
    margin-top: 3em;
    margin-bottom: 13em;
}

.checkoutButton p {
    border: 1px solid #000000;
    width: max-content;
    padding: 8px;
    border-radius: 10px;
    background-color: #500157;
    color: #fff;
    text-decoration: none !important;
    ;
}

.checkoutButton Link {
    text-decoration: none;
}

.emptyCart {
    text-align: center;
    padding: 20px 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, #ffffff, #f9f5fa);
    border-radius: 12px;
    margin: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.03);
    animation: fadeIn 0.5s ease-in-out;
}

.emptyCart-icon {
    width: 100%;
    max-width: 320px;
    margin: -10px auto -20px;
    position: relative;
    animation: floatAnimation 3s ease-in-out infinite;
}

.emptyCart iframe {
    width: 100%;
    height: 380px;
    display: block;
    pointer-events: none;
}

.emptyCart-title {
    font-size: 26px;
    font-weight: 700;
    color: #500157;
    margin: 0 0 12px;
    letter-spacing: -0.5px;
    animation: fadeInUp 0.6s ease-in-out;
}

.emptyCart-message {
    font-size: 15px;
    line-height: 1.5;
    color: #666;
    margin: 0 0 25px;
    max-width: 280px;
    animation: fadeInUp 0.8s ease-in-out;
}

.emptyCart-actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 280px;
    animation: fadeInUp 1s ease-in-out;
}

.emptyCart-shop-btn {
    display: inline-block;
    background: linear-gradient(135deg, #500157, #7b0282);
    color: white;
    padding: 16px 20px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(80, 1, 87, 0.2);
    width: 100%;
    text-align: center;
    letter-spacing: 0.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    box-sizing: border-box;
}

.emptyCart-shop-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(80, 1, 87, 0.3);
    background: linear-gradient(135deg, #5c0166, #8c0294);
}

.emptyCart-featured-btn {
    display: inline-block;
    background: transparent;
    color: #500157;
    padding: 11px 20px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    border: 2px solid #500157;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    text-align: center;
}

.emptyCart-featured-btn:hover {
    background-color: rgba(80, 1, 87, 0.05);
    transform: translateY(-3px);
}


/* Media queries for responsive design */

@media (max-width: 480px) {
    .emptyCart {
        padding: 15px 10px 25px;
        margin: 0;
        border-radius: 0;
        height: 100%;
        justify-content: center;
    }
    .emptyCart-icon {
        max-width: 100%;
        margin: 0 auto;
    }
    .emptyCart iframe {
        height: 420px;
    }
    .emptyCart-title {
        font-size: 28px;
        margin-top: -10px;
    }
    .emptyCart-message {
        font-size: 16px;
        margin-bottom: 40px;
        padding: 0 15px;
        max-width: 100%;
    }
    .emptyCart-actions {
        gap: 15px;
        max-width: 90%;
        width: 90%;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .emptyCart-shop-btn {
        padding: 18px 20px;
        font-size: 18px;
        border-radius: 50px;
        font-weight: 700;
        box-shadow: 0 6px 20px rgba(80, 1, 87, 0.25);
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
    .cart-close-btn {
        font-size: 30px;
        width: 50px;
        height: 50px;
    }
    .cartModalHeader {
        padding: 15px 10px;
    }
    .mycart {
        font-size: 22px;
    }
    .cartModalContent {
        width: 100%;
    }
}


/* Fix for very small screens */

@media (max-width: 360px) {
    .emptyCart-actions {
        width: 95%;
        max-width: 95%;
        padding: 0 5px;
    }
    .emptyCart-shop-btn {
        padding: 16px 15px;
        font-size: 16px;
    }
    .emptyCart iframe {
        height: 380px;
    }
}

@keyframes floatAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.selectedSize {
    font-size: 13px;
}


/* Featured Items Section */

.featuredItems {
    padding: 15px;
    width: 100%;
}

.featuredItems-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.back-button {
    background: none;
    border: none;
    font-size: 14px;
    color: #666;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    transition: all 0.2s ease;
    position: absolute;
    left: 0;
}

.back-button:hover {
    background-color: #f5f0ff;
    color: #500157;
}

.featuredItems-title {
    font-size: 18px;
    font-weight: 600;
    color: #500157;
    margin: 0 auto;
    text-align: center;
}

.featuredItems-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    margin-top: 10px;
}

.featuredItem {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featuredItem:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.featuredItem-image {
    width: 100%;
    height: 180px;
    overflow: hidden;
}

.featuredItem-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.featuredItem:hover .featuredItem-image img {
    transform: scale(1.05);
}

.featuredItem-info {
    padding: 15px;
    text-align: center;
}

.featuredItem-info h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;
    color: #333;
}

.featuredItem-price {
    font-size: 15px;
    font-weight: 700;
    color: #500157;
    margin: 0 0 15px;
}

.view-product-btn {
    display: inline-block;
    background-color: #f5f0ff;
    color: #500157;
    border: 1px solid #500157;
    border-radius: 6px;
    padding: 8px 15px;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
}

.view-product-btn:hover {
    background-color: #500157;
    color: white;
}

@media (min-width: 480px) {
    .featuredItems-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }
    .featuredItem-image {
        height: 150px;
    }
}


/* Add animation to the empty cart elements */

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.emptyCart-title {
    animation: fadeInUp 0.6s ease forwards;
}

.emptyCart-message {
    animation: fadeInUp 0.6s 0.2s ease forwards;
    opacity: 0;
    transform: translateY(20px);
    animation-fill-mode: forwards;
}

.emptyCart-actions {
    animation: fadeInUp 0.6s 0.4s ease forwards;
    opacity: 0;
    transform: translateY(20px);
    animation-fill-mode: forwards;
}

.emptyCart-benefits {
    animation: fadeInUp 0.6s 0.6s ease forwards;
    opacity: 0;
    transform: translateY(20px);
    animation-fill-mode: forwards;
}


/* Add a subtle pulse animation to the cart icon */

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.emptyCart-icon {
    animation: pulse 3s infinite ease-in-out;
}