/* Modern Help Widget Styles */

.help-widget {
    position: relative;
    z-index: 1000;
    margin: 2rem auto;
    max-width: 1200px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}


/* Trigger Button Styles */

.help-trigger {
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #500157 0%, #7b0282 100%);
    color: white;
    border: none;
    border-radius: 50px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(80, 1, 87, 0.2);
    transition: all 0.3s ease;
    margin: 0 auto;
}

.help-trigger:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(80, 1, 87, 0.3);
}

.help-icon {
    margin-right: 10px;
    font-size: 20px;
    display: flex;
    align-items: center;
}


/* Expanded Content Styles */

.help-content {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    padding: 24px;
    max-width: 400px;
    margin: 0 auto;
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.help-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.help-header h3 {
    margin: 0;
    color: #333;
    font-size: 18px;
    font-weight: 600;
}

.close-button {
    background: none;
    border: none;
    color: #999;
    font-size: 18px;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease;
}

.close-button:hover {
    color: #500157;
}

.help-message {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.whatsapp-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25D366;
    color: white;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    transition: all 0.2s ease;
    box-shadow: 0 4px 8px rgba(37, 211, 102, 0.2);
}

.whatsapp-button:hover {
    background-color: #1fbd59;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(37, 211, 102, 0.3);
}

.whatsapp-button svg {
    margin-right: 8px;
    font-size: 20px;
}

.help-footer {
    margin-top: 16px;
    text-align: center;
    font-size: 12px;
    color: #999;
}

.help-footer p {
    margin: 0;
}


/* Responsive Styles */

@media (max-width: 768px) {
    .help-widget {
        margin: 1.5rem auto;
    }
    .help-content {
        max-width: 90%;
        padding: 20px;
    }
    .help-trigger {
        padding: 10px 20px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .help-widget {
        margin: 1rem auto;
    }
    .help-content {
        padding: 16px;
    }
    .help-header h3 {
        font-size: 16px;
    }
    .help-message {
        font-size: 13px;
    }
    .whatsapp-button {
        padding: 10px 16px;
        font-size: 14px;
    }
}